/*
 * Copyright (C) 2024 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { useMutation } from '@apollo/client';
import { styled, Theme, Typography } from '@material-ui/core';
import { graphql, Link } from 'gatsby';
import gql from 'graphql-tag';
import React, { useMemo, useState } from 'react';
import isEmail from 'validator/es/lib/isEmail';

import Layout from 'components/partials/Layout';
import { Overflow } from 'components/partials/Overflow';
import {
  Button,
  Col,
  Container,
  Form,
  FormInput,
  useAlert,
} from 'components/UI';
import { Breakpoints } from 'utils/enums';

// Terminal API Mutation
const SEND_ACCOUNT_DELETION_VERIFICATION_MUTATION = gql`
  mutation SendAccountDeletionVerification($email: String!) {
    sendAccountDeletionVerification(email: $email)
  }
`;

// #region Styled Components
const SettingsContainer = styled(Container)(({ theme }) => ({
  marginBottom: theme.spacing(25),
  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginBottom: 0,
  },
}));

const Spacer = styled('div')<Theme, { size?: 'thin' | 'regular' }>(
  ({ size = 'regular' }) => {
    switch (size) {
      case 'thin':
        return { height: '1em' };
      case 'regular':
      default:
        return { height: '2em' };
    }
  }
);

const SuccessMessage = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.success.light,
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.success.contrastText,
  marginTop: theme.spacing(3),
  padding: theme.spacing(2),
  textAlign: 'center',
}));

// #endregion Styled Components

export const query = graphql`
  query DeleteAccountQuery {
    deleteAccountYaml {
      metadata {
        title
        description
      }
      content {
        p1
        p2
        p3
        error
        success
        form {
          label
          placeholder
          button
        }
      }
    }
  }
`;

interface Props {
  data: {
    deleteAccountYaml: {
      metadata: MetaData;
      content: {
        error: string;
        p1: string;
        p2: string;
        p3: string;
        success: string;
        form: {
          label: string;
          placeholder: string;
          button: string;
        };
      };
    };
  };
}

const DeleteAccount: React.FC<Props> = ({ data: gqlData }) => {
  const data = gqlData.deleteAccountYaml;

  const [successMessage, setSuccessMessage] = useState('');

  const alert = useAlert();

  const [emailAddress, setEmailAddress] = useState('');

  const emailAddressValid = useMemo(() => {
    return isEmail(emailAddress);
  }, [emailAddress]);

  const [sendDeletionVerification] = useMutation(
    SEND_ACCOUNT_DELETION_VERIFICATION_MUTATION,
    {
      onCompleted: () => {
        setSuccessMessage(data.content.success);
      },
      onError: () => {
        alert.error(data.content.error);
      },
    }
  );

  const submit = () => {
    sendDeletionVerification({ variables: { email: emailAddress } });
  };

  return (
    <Overflow>
      <Layout
        title={data.metadata.title}
        description={data.metadata.description}
      >
        <SettingsContainer>
          <Col md={3} />
          <Col md={6} sm={12}>
            <Typography variant="h3">{data.metadata.title}</Typography>
            <Spacer />

            <>
              <Form>
                <Typography>
                  {data.content.p1}{' '}
                  <Link to="/privacy-policy">Privacy Policy</Link>.
                </Typography>

                <Spacer size="thin" />
                <Typography>{data.content.p2}</Typography>
                <Spacer size="thin" />
                <Typography>{data.content.p3}</Typography>

                <Spacer size="thin" />

                <FormInput
                  onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
                    setEmailAddress(target.value)
                  }
                  name="email-address"
                  placeholder={data.content.form.placeholder}
                  label={data.content.form.label}
                  value={emailAddress}
                />
                <Button
                  disabled={!emailAddressValid}
                  onClick={submit}
                  type="button"
                >
                  {data.content.form.button}
                </Button>

                {successMessage && (
                  <SuccessMessage>{successMessage}</SuccessMessage>
                )}
              </Form>
              <Spacer />
            </>
          </Col>
        </SettingsContainer>
      </Layout>
    </Overflow>
  );
};

export default DeleteAccount;
